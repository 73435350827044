import React from "react";
import { useParams } from "react-router-dom";
import { useGetProductsQuery } from "./productApiSlice.js";
import PayPalButton from "../payment/paypal/PayPalButton.js";

const ProductDetails = () => {
  const { productSlug } = useParams();
  const { data, error, isLoading } = useGetProductsQuery();

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading product details</div>;

  const product = data?.data?.find((p) => p.slug === productSlug);
  if (!product) return <div>Product not found</div>;

  return (
    <section class="py-8 bg-white md:py-16 dark:bg-gray-900 antialiased">
      <div class="max-w-screen-xl px-4 mx-auto 2xl:px-0">
        <div class="lg:grid lg:grid-cols-2 lg:gap-8 xl:gap-16">
          <div class="shrink-0 max-w-md lg:max-w-lg mx-auto">
            <img class="w-full dark:hidden" src={product.images[0]} alt="" />
          </div>

          <div class="mt-6 sm:mt-8 lg:mt-0">
            <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
              {product.title}
            </h1>
            <div class="mt-4 sm:items-center sm:gap-4 sm:flex">
              <p class="text-2xl font-extrabold text-gray-900 sm:text-3xl dark:text-white">
                ${product.price}
              </p>
            </div>

            <div class="mt-6 sm:gap-4 sm:items-center sm:flex sm:mt-8">
              <PayPalButton products={[product]} />
            </div>

            <hr class="my-6 md:my-8 border-gray-200 dark:border-gray-800" />
            <p class="mb-6 text-gray-500 dark:text-gray-400" dangerouslySetInnerHTML={{ __html: product.description }}/>              
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductDetails;
