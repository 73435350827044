import React from "react";
import { Link } from "react-router-dom";
import { useGetProductsQuery } from "./productApiSlice.js";
import ProductCard from "../../components/ProductCard.jsx";

const ProductList = () => {
  const { data, error, isLoading } = useGetProductsQuery();
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading products</p>;

  return (
    <section class="bg-gray-50 py-8 antialiased dark:bg-gray-900 md:py-12">
      <div class="mx-auto max-w-screen-xl px-4 2xl:px-0">
        <div class="mb-4 grid gap-4 sm:grid-cols-2 md:mb-8 lg:grid-cols-3 xl:grid-cols-4">
          {data?.data?.map((product) => (
            <Link to={`/product/${product.slug}`} key={product._id}>
              <ProductCard product={product} />
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProductList;
