import React, {useState} from 'react'
import { EMAIL_US, WHATSAPP_US } from '../../config/config.js';
import { useSubmitContactMutation } from './contactApiSlice.js';
const ContactForm = () => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [submitContact, { isLoading, isSuccess, isError, error }] = useSubmitContactMutation();
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const requestBody = { fullName, email, subject, message }
            await submitContact(requestBody).unwrap();
            setEmail('');
            setSubject('');
            setFullName('');
            setMessage('');
        } catch (err) {
            console.error('Failed to send message:', err);
        }
    };

    return (
    <>
        <section class="bg-white dark:bg-gray-900">
            <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">Contact Us</h2>
                <form onSubmit={handleSubmit} class="space-y-8">
                    <div>
                        <label for="fullName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your name</label>
                        <input type="text" id="fullName" value={fullName} onChange={(e) => setFullName(e.target.value)} class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Please enter your full name" required/>
                    </div>
                    <div>
                        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
                        <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="name@example.com" required/>
                    </div>
                    <div>
                        <label for="subject" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Subject</label>
                        <input type="text" id="subject" value={subject} onChange={(e) => setSubject(e.target.value)} class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Let us know how we can help you" required/>
                    </div>
                    <div class="sm:col-span-2">
                        <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your message</label>
                        <textarea id="message" rows="6" value={message} onChange={(e) => setMessage(e.target.value)} class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Leave a comment..." required></textarea>
                    </div>
                    <button type="submit" disabled={isLoading} class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">{isLoading ? 'Sending...' : 'Send message'}</button>
                    {isSuccess && <p className="text-green-500">Message sent successfully!</p>}
                    {isError && <p className="text-red-500">Error: {error?.data?.message || 'Failed to send message'}</p>}
                </form>
            </div>
        </section>
        <section class="bg-white dark:bg-gray-900">
            <div class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
                <dl class="grid max-w-screen-md gap-8 mx-auto text-gray-900 sm:grid-cols-2 dark:text-white">
                    <div class="flex flex-col items-center justify-center">
                        <dt class="mb-2 text-2xl md:text-2xl font-extrabold">Email us:</dt>
                        <dd class="font-light text-gray-500 dark:text-gray-400">{EMAIL_US}</dd>
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <dt class="mb-2 text-2xl md:text-2xl font-extrabold">Whatsapp us:</dt>
                        <a href={`https://wa.me/${WHATSAPP_US.replace(/[^\d]/g, '')}`}>
                        <dd class="font-light text-gray-500 dark:text-gray-400 hover:text-primary-600">{WHATSAPP_US}</dd>
                        </a>
                    </div>
                </dl>
            </div>
        </section>
    </>
  )
}

export default ContactForm
